import React from "react";

const Instagram = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Insta_default</title>
    <g
      id="Insta_default"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-15">
        <g id="Group-17" fill="#787872">
          <circle id="Oval" cx="15" cy="15" r="15" />
        </g>
        <g
          id="Group-8"
          transform="translate(8.000000, 8.000000)"
          stroke="#181912"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        >
          <rect id="Rectangle" x="0" y="0" width="14" height="14" rx="3.25" />
          <path
            d="M9.8,6.559 C9.97718571,7.75388012 9.36811809,8.92769116 8.28913857,9.47077169 C7.21015905,10.0138522 5.904534,9.80375946 5.05038727,8.94961273 C4.19624054,8.095466 3.98614778,6.78984095 4.52922831,5.71086143 C5.07230884,4.63188191 6.24611988,4.02281429 7.441,4.2 C8.66108374,4.38092309 9.61907691,5.33891626 9.8,6.559 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Instagram;
