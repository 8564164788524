import React from "react";

const Facebook = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>facebook</title>
    <g id="fb" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-17">
        <circle id="Oval" fill="#787872" cx="15" cy="15" r="15" />
        <path
          d="M15.0833333,13.8333333 L17.75,13.8333333 L17.75,16.5 L15.0833333,16.5 L15.0833333,22.7222222 L12.4166667,22.7222222 L12.4166667,16.5 L9.75,16.5 L9.75,13.8333333 L12.4166667,13.8333333 L12.4166667,12.7177778 C12.4166667,11.6608889 12.7491111,10.3257778 13.4104444,9.596 C14.0717778,8.86444444 14.8975556,8.5 15.8868889,8.5 L17.75,8.5 L17.75,11.1666667 L15.8833333,11.1666667 C15.4406667,11.1666667 15.0833333,11.524 15.0833333,11.9657778 L15.0833333,13.8333333 Z"
          id="Path"
          fill="#181912"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default Facebook;
