import React, { Component } from "react";
import Helmet from "react-helmet";
import Facebook from "../../icons/fb";
import Instagram from "../../icons/Insta";
import Twitter from "../../icons/twitter";
import { Message } from "../../lib/getMessages";
// import Linkedin from "../icons/Linkedin";
import "../../commonStyle/style.css";
import Youtube from "../../icons/youtube";
import EmailIcon from "../../images/email-grey.svg";
import Android from "../../images/google-play-badge.png";
import IosLogo from "../../images/ios-app.png";
import twemoji from "twemoji";

class Layout extends Component {
  render() {
    const { language, isDirectionRTL } = this.props;
    const locationOrigin =
      this.props.location?.origin ?? "https://zopping.com/";
    const lang = language ? `/${language}` : "";
    return (
      <div>
        <Helmet
          link={[
            {
              rel: "alternate",
              href: `${locationOrigin}${lang}`,
              hrefLang: `${language || "en"}`,
            },
          ]}
        />

        {this.props.children}
        <footer>
          <div className="footer-section-wrp">
            <img
              className="footer-logo"
              src={
                "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20231228/logodark-20231228-122145.webp"
              }
              srcSet={
                "https://storage.googleapis.com/zopping-uploads/3585/images/640/20231228/logodark-20231228-122145.webp 2x, https://storage.googleapis.com/zopping-uploads/3585/images/1024/20231228/logodark-20231228-122145.webp 3x"
              }
              alt="footer-logo"
            />
            <a
              href="https://play.google.com/store/apps/details?id=com.zopping.admin"
              target="_blank"
              className="android-wrp"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="play-store-logo" />
            </a>
            <a
              href="https://apps.apple.com/in/app/zopping/id1586937529"
              target="_blank"
              className="ios-wrp"
              rel="noopener noreferrer"
            >
              <img src={IosLogo} alt="play-store-logo" />
            </a>
          </div>
          <div className="footer-section-wrp">
            <p className="footer-section-header">
              <Message dictKey="company.text" />
            </p>
            <a href={`${lang}/about-us`}>
              <Message dictKey="about.us" />
            </a>
            <a href={`${lang}/features`}>
              <Message dictKey="features" />
            </a>
            <a href={`${lang}/whyus`}>
              <Message dictKey="whyus" />
            </a>
            {/* <a><Message dictKey='blog.text'/></a> */}
          </div>
          <div className="footer-section-wrp">
            <p className="footer-section-header">
              <Message dictKey="contact.us" />
            </p>
            <div className="contact-us-wrp">
              <img src={EmailIcon} className="contact-us-img" alt="email" />
              <a
                href="mailto:support@zopping.com"
                className="contact-number-link"
              >
                support@zopping.com
              </a>
            </div>
            <div className="contact-us-wrp">
              <div
                className="contact-us-flag"
                dangerouslySetInnerHTML={{
                  __html: twemoji.parse("🇮🇳", {
                    base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
                  }),
                }}
              />
              <a href="tel:+919916814808" className="contact-number-link">
                +91 99168 14808
              </a>
            </div>
            <div className="contact-us-wrp">
              <div
                className="contact-us-flag"
                dangerouslySetInnerHTML={{
                  __html: twemoji.parse("🇬🇧", {
                    base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
                  }),
                }}
              />
              <a href="tel:+447418310190" className="contact-number-link">
                +44 74183 10190
              </a>
            </div>
            <div className="contact-us-wrp">
              <div
                className="contact-us-flag"
                dangerouslySetInnerHTML={{
                  __html: twemoji.parse("🇺🇸", {
                    base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
                  }),
                }}
              />
              <a href="tel:+12089133258" className="contact-number-link">
                +1 208 913 3258
              </a>
            </div>
          </div>
          <div className="footer-section-wrp">
            <p className="footer-section-header">
              <Message dictKey="stay.in.touch" />
            </p>
            <div className="social-links-wrp">
              <a
                href="https://www.facebook.com/ZoppingApp"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook className="fb-icon" />
              </a>
              <a
                href="https://twitter.com/ZoppingApp"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className="twitter-icon" />
              </a>
              <a
                href="https://www.instagram.com/zoppingapp/"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram className="instagram-icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCaS37djLuCYh96jN9ehKyRw"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Youtube className="youtube-icon" />
              </a>
              {/* <a
                href="https://twitter.com/ZoppingApp"
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin className="linkedin-icon" />
              </a> */}
            </div>
            <div
              className={`address-wrp ${isDirectionRTL && "rtl-address-wrp"}`}
            >
              #409, 24th Main Rd, Parangi Palaya,
              <br /> Sector 2, HSR Layout,
              <br /> Bengaluru, Karnataka 560102
              <br />
            </div>
          </div>
        </footer>
        <div className="footer-info-wrp">
          <div className="copyright">
            &copy; Raramuri Consultancy Services LLP {new Date().getFullYear()}.
            <p>&nbsp;All rights reserved</p>
          </div>

          <div className="policy-wrp">
            <a href={`${lang}/privacy-policy`} className="privacy-policy-text">
              <Message dictKey="privacy.policy" />
            </a>
            <span>|</span>
            <a href={`${lang}/terms-condition`}>
              <Message dictKey="terms.conditions" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
