/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Link from "gatsby-link";
import React, { Component } from "react";
import ActiveUserIcon from "../../images/active-user.svg";
import enterpriseLogo from "../../images/enterprise-logo.svg";
import UserIcon from "../../images/icon-profile-image.svg";
import LanguageIconHover from "../../images/Language-icon-hover.svg";
import LanguageIcon from "../../images/LanguageIcon";
import Tick from "../../images/tick.webp";
import { Message } from "../../lib/getMessages";
import { clearSession } from "../../lib/session";
import ForgotPassword from "../ForgotPassword";
import LoginForm from "../LoginForm";
import Popup from "../Popup";
import SignupForm from "../SignupForm";
import "./header-eazy.css";
import "./header.css";
import axios from "axios";

export default class EazyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showLangSelectorMob: false,
      showUserDropDown: false,
      isLoggedin: false,
      dropDown: false,
      country_code: "IN",
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick);
    if (this.props.current !== "features") {
      let headers = document.querySelectorAll("header > div");
      headers.forEach((heading) => {
        heading.classList.add("relative");
      });
      let subHeader = document.querySelector(".eazy-header > div");
      let subHeaderHeight = subHeader.offsetTop;
      window.onscroll = (e) => {
        if (window.pageYOffset >= subHeaderHeight) {
          subHeader.classList.add("fixed");
          this.setState({ hiddenHeader: true });
        } else {
          subHeader.classList.remove("fixed");
          this.setState({ hiddenHeader: false });
        }
      };
    }
    if (window.localStorage.getItem("token")) {
      this.setState({ isLoggedin: true });
    }

    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const { country_code } = response?.data;
        this.setState({ country_code });
        console.log(country_code, "country_code");
      })
      .catch((error) => {
        console.error("Error fetching user country:", error);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoggedinFromHero !== prevProps.isLoggedinFromHero) {
      this.setState({ isLoggedin: true });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick);
    window.onscroll = null;
  }

  handleClick(e) {
    if (this.toggleRef && this.toggleRef.contains(e.target)) {
      this.toggle();
    } else {
      this.hide();
    }
  }

  toggle() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  hide() {
    this.setState({
      expanded: false,
    });
  }

  toggleLanguageSelectorMob = () => {
    this.setState((prevState) => ({
      showLangSelectorMob: !prevState.showLangSelectorMob,
      showUserDropDown: false,
    }));
  };
  toggleUserDropDown = () => {
    this.setState((prevState) => ({
      showUserDropDown: !prevState.showUserDropDown,
      showLangSelectorMob: false,
    }));
  };
  handleClickShowLoginForm = () => {
    this.setState({
      showLoginForm: true,
    });
    if (window.gtag) {
      window.gtag("event", "button_click", {
        category: "Button",
        label: "Sign In",
      });
    }
  };
  handleClickCloseLoginForm = () => {
    this.setState({
      showLoginForm: false,
    });
  };
  setIsLoggedIn = (isLoggedin) => {
    this.setState({ isLoggedin });
  };
  handleClickShowSignupForm = () => {
    this.setState({
      showSignupForm: true,
    });

    if (window.gtag) {
      window.gtag("event", "button_click", {
        category: "Button",
        label: "Sign Up",
      });
    }
  };
  handleClickCloseSignupForm = () => {
    this.setState({
      showSignupForm: false,
    });
  };
  handleClickShowForgotPassword = () => {
    this.setState({
      showForgotPassword: true,
    });
  };
  handleClickCloseForgotPassword = () => {
    this.setState({
      showForgotPassword: false,
    });
  };
  handleLogoutClick = () => {
    clearSession();
    window.location.reload();
  };

  render() {
    let { current, enterprise, language, updateLanguage, isDirectionRTL } =
      this.props;
    const { expanded, hiddenHeader, isLoggedin, country_code } = this.state;
    const lang = language ? `/${language}` : "";
    const allLanguages = [
      { code: "en", name: "English" },
      { code: "hi", name: "हिंदी" },
      { code: "kn", name: "ಕನ್ನಡ" },
      { code: "ta", name: "தமிழ்" },
      { code: "te", name: "తెలుగు" },
      { code: "ml", name: "മലയാളം" },
      { code: "ar", name: "عربي" },
    ];
    return (
      <header className="eazy-header">
        <div className={`${isDirectionRTL ? "rtl" : ""}`}>
          <div className="wrap">
            <div>
              <Link
                to={enterprise ? `${lang}/enterprise` : `${lang}/`}
                className="logo-link"
              >
                <img
                  className="logo"
                  src={
                    enterprise
                      ? enterpriseLogo
                      : "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20240102/logo-20240102-070752.webp"
                  }
                  alt="Zopping Logo"
                />
              </Link>
            </div>
            {isLoggedin && (
              <>
                <button
                  className="user-icon-wrp-mobile"
                  onClick={this.toggleUserDropDown}
                >
                  {this.state.showUserDropDown ? (
                    <img
                      className="user-icon-mobile"
                      src={ActiveUserIcon}
                      alt="User Icon"
                    />
                  ) : (
                    <img
                      className="user-icon-mobile"
                      src={UserIcon}
                      alt="Active User Icon"
                    />
                  )}
                </button>
                {this.state.showUserDropDown && (
                  <ul className="submenu submenu-mobile">
                    <li className="user-option-mobile">
                      <a href="/admin/user" target="_blank">
                        <Message dictKey="my.dashboard" />
                      </a>
                    </li>
                    <li className="user-option-mobile">
                      <span onClick={this.handleLogoutClick}>
                        <Message dictKey="logout.text" />
                      </span>
                    </li>
                  </ul>
                )}
              </>
            )}
            <div
              className="language-selector-icon-wrp-mobile"
              onClick={this.toggleLanguageSelectorMob}
            >
              <LanguageIcon />
            </div>
            {this.state.showLangSelectorMob && (
              <ul
                className={`submenu submenu-mobile ${
                  isDirectionRTL ? "submenuRTL" : ""
                }`}
              >
                <li className="language-selector-heading">
                  <Message dictKey="select.language.heading" />
                </li>
                {allLanguages.map(({ code, name }) => (
                  <li
                    className={`${code === language ? "selected-lang" : ""}`}
                    key={code}
                    onClick={() => updateLanguage(code)}
                  >
                    <span>{name}</span>
                    {code === language && (
                      <img
                        className={`${
                          isDirectionRTL ? "language-tickRTL" : "language-tick"
                        }`}
                        src={Tick}
                        alt="selected"
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}
            {!enterprise && (
              <div className="menu-button-container">
                <div
                  className={`hamburger ${expanded ? "close" : ""}`.trim()}
                  ref={(node) => {
                    this.toggleRef = node;
                  }}
                >
                  <span />
                  <span />
                  <span />
                </div>
                <nav
                  className={`site-nav ${expanded ? "expanded" : ""} ${
                    hiddenHeader ? "hidden-header" : ""
                  } ${isLoggedin ? "login" : ""} ${
                    isDirectionRTL && "rtl-support"
                  }`.trim()}
                >
                  <ul>
                    <li
                      className={`${
                        current === "home" ? "current-page" : ""
                      }`.trim()}
                    >
                      <Link
                        to={`${lang}/`}
                        className={`${
                          current === "home" ? "current" : ""
                        }`.trim()}
                      >
                        <Message dictKey="home" />
                      </Link>
                    </li>
                    <li
                      className={`${
                        current === "solutions/ondc" ||
                        current === "solutions/hyperlocal"
                          ? "current-page"
                          : ""
                      }`.trim()}
                      onMouseEnter={() =>
                        this.setState({
                          dropDown: true,
                        })
                      }
                      onMouseLeave={() =>
                        this.setState({
                          dropDown: false,
                        })
                      }
                    >
                      <Link
                        // to={`${lang}/solutions`}
                        className={`${
                          current === "solutions/ondc" ||
                          current === "solutions/hyperlocal"
                            ? "current"
                            : ""
                        }`.trim()}
                      >
                        <Message dictKey="solutions" />
                      </Link>
                      {(this.state.dropDown || this.state.expanded) && (
                        <div
                          className={` dropdownMenu ${
                            isDirectionRTL && "rtl-support"
                          }`}
                        >
                          <Link
                            to={`${lang}/solutions/hyperlocal`}
                            className={`${
                              current === "solutions/hyperlocal"
                                ? "solutions-pages"
                                : ""
                            }`.trim()}
                            style={{ padding: "16px" }}
                          >
                            <Message dictKey="Hyperlocal" />
                          </Link>
                          {country_code === "IN" ? (
                            <Link
                              to={`${lang}/solutions/ondc`}
                              className={`${
                                current === "solutions/ondc"
                                  ? "solutions-pages"
                                  : ""
                              }`.trim()}
                              style={{ padding: "16px" }}
                            >
                              <Message dictKey="ONDC" />
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </li>
                    <li
                      className={`${
                        current === "features" ? "current-page" : ""
                      }`.trim()}
                    >
                      <Link
                        to={`${lang}/features`}
                        className={`${
                          current === "features" ? "current" : ""
                        }`.trim()}
                      >
                        <Message dictKey="features" />
                      </Link>
                    </li>
                    <li
                      className={`${
                        current === "whyus" ? "current-page" : ""
                      }`.trim()}
                    >
                      <Link
                        to={`${lang}/whyus`}
                        className={`${
                          current === "whyus" ? "current" : ""
                        }`.trim()}
                      >
                        <Message dictKey="whyus" />
                      </Link>
                    </li>
                    <li
                      className={`${
                        current === "contact" ? "current-page" : ""
                      }`.trim()}
                    >
                      <Link
                        to={`${lang}/contact`}
                        className={`${
                          current === "contact" ? "current" : ""
                        }`.trim()}
                      >
                        <Message dictKey="contact" />
                      </Link>
                    </li>
                    <li
                      className={`${
                        current === "blog" ? "current-page" : ""
                      }`.trim()}
                    >
                      <Link
                        to={`${lang}/blog`}
                        className={`${
                          current === "blog" ? "current" : ""
                        }`.trim()}
                      >
                        <Message dictKey="blog.text" />
                      </Link>
                    </li>
                  </ul>
                  {!isLoggedin && (
                    <div className="login-signup-mobile">
                      <button
                        className="login-mobile"
                        onClick={this.handleClickShowLoginForm}
                      >
                        <Message dictKey="signin" />
                      </button>
                      <button
                        className="signup-mobile"
                        onClick={this.handleClickShowSignupForm}
                      >
                        <Message dictKey="signup" />
                      </button>
                    </div>
                  )}
                </nav>
                <div
                  className={`header-links ${
                    isDirectionRTL && "header-links-rtl"
                  } ${isLoggedin ? "border" : ""}`}
                >
                  {isLoggedin ? (
                    <>
                      <div
                        className={`user-icon-wrp ${
                          isDirectionRTL ? "rtl" : ""
                        }`}
                      >
                        <img src={UserIcon} alt="user" className="user-icon" />
                        <img
                          src={ActiveUserIcon}
                          alt="active-user"
                          className="user-icon"
                        />
                      </div>
                      <ul
                        className={`user-menu ${
                          isDirectionRTL ? "user-menuRTL" : ""
                        }`}
                      >
                        <li className="user-menu-option">
                          <a href="/admin/home" target="_blank">
                            <Message dictKey="my.dashboard" />
                          </a>
                        </li>
                        <li className="user-menu-option">
                          <span onClick={this.handleLogoutClick}>
                            <Message dictKey="logout.text" />
                          </span>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <span
                        className="smartstore-login"
                        onClick={this.handleClickShowLoginForm}
                      >
                        <Message dictKey="signin" />
                      </span>

                      <span
                        className="smartstore-signup"
                        onClick={this.handleClickShowSignupForm}
                      >
                        <Message dictKey="signup" />
                      </span>
                    </>
                  )}
                </div>
                <Popup
                  show={this.state.showLoginForm}
                  close={this.handleClickCloseLoginForm}
                >
                  <LoginForm
                    isDirectionRTL={isDirectionRTL}
                    close={this.handleClickCloseLoginForm}
                    showSignup={this.handleClickShowSignupForm}
                    showForgotPasswordForm={this.handleClickShowForgotPassword}
                    setIsLoggedIn={this.setIsLoggedIn}
                  />
                </Popup>
                <Popup
                  show={this.state.showSignupForm}
                  close={this.handleClickCloseSignupForm}
                >
                  <SignupForm
                    isDirectionRTL={isDirectionRTL}
                    close={this.handleClickCloseSignupForm}
                    showLogin={this.handleClickShowLoginForm}
                    setIsLoggedIn={this.setIsLoggedIn}
                  />
                </Popup>
                <Popup
                  show={this.state.showForgotPassword}
                  close={this.handleClickCloseForgotPassword}
                >
                  <ForgotPassword
                    isDirectionRTL={isDirectionRTL}
                    close={this.handleClickCloseForgotPassword}
                    showLogin={this.handleClickShowLoginForm}
                    setIsLoggedIn={this.setIsLoggedIn}
                  />
                </Popup>
                <nav
                  className={`language-selector site-nav ${
                    expanded ? "expanded" : ""
                  } ${isDirectionRTL ? "rtl" : ""}`.trim()}
                >
                  <ul>
                    <li className="submenu-link">
                      <p className="language-selector-icon-wrp">
                        <LanguageIcon />
                      </p>
                      <ul
                        className={`submenu ${
                          isDirectionRTL ? "submenuRTL" : ""
                        }`}
                      >
                        <li className="language-selector-heading">
                          <Message dictKey="select.language.heading" />
                        </li>
                        {allLanguages.map(({ code, name }) => (
                          <li
                            className={code === language ? "selected-lang" : ""}
                            key={code}
                            onClick={() => updateLanguage(code)}
                          >
                            <span>{name}</span>
                            {code === language && (
                              <img
                                className={`${
                                  isDirectionRTL
                                    ? "language-tickRTL"
                                    : "language-tick"
                                }`}
                                src={Tick}
                                alt="selected"
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}
