import React from "react";

const Youtube = (props) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Youtube</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Youtube">
        <g id="Group-18" fill="#787872">
          <circle id="Oval-Copy-2" cx="15" cy="15" r="15"></circle>
        </g>
        <g
          id="YouTube_full-color_icon_(2017)"
          transform="translate(8.000000, 10.000000)"
          fill="#181912"
          fillRule="nonzero"
        >
          <path
            d="M1.52018068,0.308964552 C3.79629839,-0.176452417 11.6426317,-0.0198367532 12.4527146,0.299805741 C13.0557962,0.464664334 13.5262536,0.94733366 13.6893455,1.56372162 C14.1158935,3.32221328 14.0881141,6.64686158 13.6983065,8.43282967 C13.5370069,9.04921763 13.0647572,9.53005519 12.4616757,9.69674555 C10.7590679,10.1272097 3.13317749,10.0740886 1.52914178,9.69674555 C0.926060193,9.53188696 0.455602792,9.04921763 0.292510893,8.43282967 C-0.109842198,6.75676731 -0.0820628085,3.21230755 0.2835498,1.57288043 C0.44484948,0.956492471 0.9170991,0.475654907 1.52018068,0.308964552 Z M5.04530388,2.85511393 L5.04530388,7.14143736 L10.0453039,4.99827565 L5.04530388,2.85511393 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Youtube;
